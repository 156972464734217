import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { IoClose } from 'react-icons/io5';
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { API_URL } from '../../config';

const SubmissionForm = ({name}) => {
  const { phone: initialPhoneNumber } = useParams();
  const [submissionFormState, setSubmissionFormState] = useState({ showForm: false });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [variableValues, setVariableValues] = useState({});
  const [preview, setPreview] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [templates, setTemplates] = useState([]);
  const [previewText, setPreviewText] = useState('');

  const submissionFormRef = useRef();

  useEffect(() => {
    setPhoneNumber(initialPhoneNumber);
  }, [initialPhoneNumber]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`${API_URL}getTemplates`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        });
        setTemplates(response.data.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      setPreview(generatePreview(selectedTemplate, variableValues));
      setPreviewText(generatePreviewText(selectedTemplate, variableValues));
    }
  }, [selectedTemplate, variableValues]);

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClickOutside = event => {
    if (submissionFormRef.current && !submissionFormRef.current.contains(event.target)) {
      setSubmissionFormState(prevState => ({ ...prevState, showForm: false }));
    }
  };

  const handleEscapeKey = event => {
    if (event.key === 'Escape') {
      setSubmissionFormState(prevState => ({ ...prevState, showForm: false }));
    }
  };

  useEffect(() => {
    if (submissionFormState.showForm) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [submissionFormState.showForm]);

  const handleTemplateChange = (templateId) => {
    const template = templates.find((template) => template.id === templateId);
    setSelectedTemplate(template);
    setVariableValues({});
  };

  const handlePaperclipClick = () => {
    setSubmissionFormState(prevState => ({ ...prevState, showForm: true }));
  };

  const handleCancelClick = () => {
    setSubmissionFormState(prevState => ({ ...prevState, showForm: false }));
  };

  const handleVariableChange = (variableName, value) => {
    setVariableValues(prevState => ({
      ...prevState,
      [variableName]: value
    }));
  };

  const handleSend = async () => {
    const templateName = selectedTemplate.name;
    const templateLanguage = selectedTemplate.language;

    const headerParameters = extractParameters("HEADER", selectedTemplate, variableValues);
    const bodyParameters = extractParameters("BODY", selectedTemplate, variableValues);

    const postData = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: phoneNumber,
      type: "template",
      template: {
        name: templateName,
        language: {
          code: templateLanguage,
        },
        components: [
          {
            type: "header",
            parameters: headerParameters,
          },
          {
            type: "body",
            parameters: bodyParameters,
          },
        ],
      },
    };

    try {
      const response = await axios.post(`${API_URL}sendWhatsapptemplate`, {
        postData: postData,
        message: previewText
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
        const NewContactresponse = await axios.post(`${API_URL}createNewContact`, {
          to: phoneNumber,
          name: name || phoneNumber,
          message: '',
          timestamp: Math.floor(Date.now() / 1000),
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
      handleCancelClick();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const extractVariables = (text) => {
    const regex = /{{(.*?)}}/g;
    const matches = text.match(regex);
    return matches ? matches.map((match) => match.replace(/{{|}}/g, '')) : [];
  };

  const extractParameters = (type, template, values) => {
    if (!template || !template.components) {
      return [];
    }

    return template.components
      .filter((component) => component.type === type)
      .flatMap((component) =>
        extractVariables(component.text).map((variable) => ({
          type: "text",
          text: values[component.type + variable] || "",
        }))
      );
  };

  const generatePreview = (template, values) => {
    return template.components.map((component) => {
      if (component.type === 'HEADER' || component.type === 'BODY' || component.type === 'FOOTER') {
        let text = component.text;
        for (let key in values) {
          key = key.replace("HEADER", "").replace("BODY", "").replace("FOOTER", "");
          text = text.replace(`{{${key}}}`, values[component.type + key] || `{{${key}}}`);
        }
        return <p key={component.type}>{text}</p>;
      }
      return null;
    });
  };

  const generatePreviewText = (template, values) => {
    return template.components.map((component) => {
      if (component.type === 'HEADER' || component.type === 'BODY' || component.type === 'FOOTER') {
        let text = component.text;
        for (let key in values) {
          key = key.replace("HEADER", "").replace("BODY", "").replace("FOOTER", "");
          text = text.replace(`{{${key}}}`, values[component.type + key] || `{{${key}}}`);
        }
        return text;
      }
      return null;
    }).join('\n');
  };

  return (
    <>
      {submissionFormState.showForm && <div className="overlay"></div>}
      <div ref={submissionFormRef}>
        <BiSolidMessageSquareDetail className="icon submit-form-icon" onClick={handlePaperclipClick} />
        {submissionFormState.showForm && (
          <div className="submission-form">
            <button className="x-button" onClick={handleCancelClick}><IoClose /></button>
            <div className='center-text'>ارسال نموذج</div>
            <div>
              <label>رقم المستقبل: </label>
              <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <select onChange={(e) => handleTemplateChange(e.target.value)}>
              <option value="">اختر نموذج</option>
              {templates.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>

            {selectedTemplate && (
              <>
                <h4>بيانات النموذج المتغيرة</h4>
                {selectedTemplate.components
                  .filter((component) => component.type !== 'FOOTER')
                  .map((component) => {
                    const variables = extractVariables(component.text);
                    return (
                      <div key={component.type}>
                        <label>{component.type}</label>
                        {variables.map((variable) => (
                          <div key={variable}>
                            <label>{variable}</label>
                            <input
                              type="text"
                              value={variableValues[component.type + variable] || ''}
                              onChange={(e) => handleVariableChange(component.type + variable, e.target.value)}
                            />
                          </div>
                        ))}
                      </div>
                    );
                  })}
                <h4>معاينة الرسالة</h4>
                <div>{preview}</div>
              </>
            )}
            <button className='primary-button' onClick={handleSend}>ارسال</button>
            <button className='primary-outline-button' onClick={handleCancelClick}>الغاء</button>
          </div>
        )}
      </div>
    </>
  );
};

export default SubmissionForm;
