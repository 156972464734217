import React, { useState, useRef, useEffect } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getFileType } from './MessageUtils';
import PDFPreview from './PDFPreview';
import { API_URL } from '../../config';
import { ToastContainer, toast } from 'react-toastify';

const FilePreviewForm = () => {
  const [fileType, setFileType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const filePreviewRef = useRef();
  const [filePreviewForm, setFilePreviewForm] = useState({
    showFilePreview: false,
  });

  let { phone } = useParams();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFilePreviewForm((prevState) => ({ ...prevState, showFilePreview: true }));
    setFileType(getFileType(file.name));

  };

  const handlePaperclipClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelClick = () => {
    setFilePreviewForm((prevState) => ({ ...prevState, showFilePreview: false }));
  };

  const sendFile = async () => {
    if (!selectedFile) return;
    const MAX_FILE_SIZES = {
      audio: 16 * 1024 * 1024,
      document: 100 * 1024 * 1024,
      image: 5 * 1024 * 1024,
      video: 16 * 1024 * 1024,
    };
    const SUPPORTED_TYPES = {
      audio: ['audio/aac', 'audio/mp4', 'audio/mpeg', 'audio/amr', 'audio/ogg', 'audio/mp3'],
      image: ['image/jpeg', 'image/png'],
      video: ['video/mp4', 'video/3gp'],
      sticker: ['image/webp']
    };

    let maxSize = MAX_FILE_SIZES.document;
    if (SUPPORTED_TYPES.audio.includes(selectedFile.type)) {
      maxSize = MAX_FILE_SIZES.audio;
    } else if (SUPPORTED_TYPES.image.includes(selectedFile.type)) {
      maxSize = MAX_FILE_SIZES.image;
    } else if (SUPPORTED_TYPES.video.includes(selectedFile.type)) {
      maxSize = MAX_FILE_SIZES.video;
    }
    if (selectedFile.size > maxSize) {
      toast.error(`File size exceeds the limit. Please select a smaller file.`);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Encode the filename
      const encodedFilename = encodeURIComponent(selectedFile.name);
      formData.append('filename', encodedFilename);

      const uploadResponse = await axios.post(`${API_URL}upload`, formData);

      const uploadResult = uploadResponse.data;
      console.log(uploadResult.url, "uploadResult.urluploadResult.urluploadResult.url");

      const sendWhatsappImageResponse = await axios.post(`${API_URL}sendWhatsappImage`, {
        to: phone,
        url: uploadResult.url,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const sendWhatsappImageResult = sendWhatsappImageResponse.data;
      console.log('Response from sendWhatsappImage:', sendWhatsappImageResult);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClickOutside = event => {
    if (filePreviewRef.current && !filePreviewRef.current.contains(event.target)) {
      setFilePreviewForm(prevState => ({ ...prevState, showFilePreview: false }));
    }
  };

  const handleEscapeKey = event => {
    if (event.key === 'Escape') {
      setFilePreviewForm(prevState => ({ ...prevState, showFilePreview: false }));
    }
  };

  useEffect(() => {
    if (filePreviewForm.showFilePreview) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [filePreviewForm.showFilePreview]);

  return (
    <>
      {filePreviewForm.showFilePreview && <div className="overlay"></div>}
      <div ref={filePreviewRef}>
        <input id="fileInput" type="file" style={{ display: 'none' }} ref={fileInputRef} onClick={(event)=> { event.target.value = null}} onChange={handleFileChange} />
        <FaPaperclip className="icon send-file-icon" onClick={handlePaperclipClick} />
        {filePreviewForm.showFilePreview && (
          <div className="file-preview-form">
            <button className="x-button" onClick={handleCancelClick}><IoClose /></button>
            <div>Preview</div>
            {fileType === 'image' && (
              <img src={URL.createObjectURL(selectedFile)} alt="Preview" />
            )}
            {fileType === 'pdf' && (
              <PDFPreview selectedFile={selectedFile} />
            )}
            {fileType === 'video' && (
              <video controls>
                <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                Your browser does not support the video tag.
              </video>
            )}
            {fileType === 'audio' && (
              <audio controls>
                <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                Your browser does not support the audio tag.
              </audio>
            )}
            {fileType === 'other' && (
              <div>
                <p>File Preview Not Available</p>
                <p>{selectedFile.name}</p>
              </div>
            )}
            <div></div>
            <button className='primary-button' onClick={() => {
              sendFile();
              handleCancelClick();
            }}>حفظ</button>
            <button className='primary-outline-button' onClick={handleCancelClick}>الغاء</button>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default FilePreviewForm;
