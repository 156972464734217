import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddAdminForm from './AddAdminForm';
import '../../Styles/Header.css';
import '../../Styles/Buttons.css';
import { IoArrowForwardCircleOutline } from "react-icons/io5";

const Header = ({isActive}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const adminName = localStorage.getItem('adminName');
  const superAdmin = localStorage.getItem('superAdmin');

  const renderAddAdminForm = () => {
    if (superAdmin === 'true') {
      return <AddAdminForm />;
    }
    return null;
  };

  return (
    <div className="header">
      <div className="header-right">
        {isActive && (
          <div className='back-btn' onClick={()=>{navigate('/')}} >
            <IoArrowForwardCircleOutline className="icon" />
            <p>الرجوع</p>
          </div>
        )}
        <p className='hide-mobile admin-name'>
        {adminName || 'Admin Name'}
        </p>
      </div>

      <div className="header-left">
        {renderAddAdminForm()}
        <button className="header-button primary-button" onClick={handleLogout}>تسجيل خروج</button>
      </div>
    </div>
  );
};

export default Header;
