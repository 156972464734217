import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate,useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import '../Styles/LoginAndResetPage.css';
import { API_URL } from '../config';

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/chat' } }; // Capture 'from' here

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const superAdmin = localStorage.getItem('superAdmin');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email && password) {
      setIsLoading(true);
      try {
        const response = await axios.post(`${API_URL}adminlogin`, { email, password });
        const { status, msg, token, superAdmin, adminName } = response.data;
        if (status === 'success') {
          toast.success('Logged in successfully');
          localStorage.setItem('token', token);
          localStorage.setItem('superAdmin', superAdmin);
          localStorage.setItem('adminName', adminName);
          setIsLoading(false);
          navigate(from, { replace: true });
        } else {
          toast.error(msg, 'Error');
          setIsLoading(false);
        }
      } catch (error) {
        toast.error('Invalid email or password', 'Error');
        setIsLoading(false);
      }
    } else {
      toast.error('All fields are required', 'Error');
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (email) {
      setIsLoading(true);
      try {
        const response = await axios.post(`${API_URL}forgotPassword`, { email });
        const { status, msg } = response.data;
        if (status === 'success') {
          toast.success(msg);
        } else {
          toast.error(msg, 'Error');
        }
      } catch (error) {
        toast.error('An error occurred, please try again later', 'Error');
      }
      setIsLoading(false);
    } else {
      toast.error('Please enter your email', 'Error');
    }
  };

  return (
    <div className="page-wrapper-circles">
      <div className="background">
        <div className="shape"></div>
        <form className="submit-form" onSubmit={handleLogin}>
          <h3>تسجيل دخول</h3>
          <label htmlFor="email">الايميل</label>
          <input type="text" placeholder="ادخل الايميل هنا" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label htmlFor="password">كلمة المرور</label>
          <input type="password" placeholder="ادخل كلمة المرور هنا" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <a className="submit-form-link" onClick={handleForgotPassword}>نسيت كلمة المرور؟</a>
          <button className="primary-button" type="submit" disabled={isLoading}>تسجيل دخول</button>
        </form>
        <ToastContainer />
        <div className="shape"></div>
      </div>
    </div>
  );
};

export default LoginPage;
