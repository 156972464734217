import React, { useState, useEffect, useRef } from 'react';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import '../../Styles/Buttons.css';
import { API_URL } from '../../config';

const AddNicknameForm = ({phone}) => {
  const addNicknameRef = useRef();
  const [formData, setFormData] = useState({
    showAddNicknameForm: false,
    name: '',
  });

  useEffect(() => {
    const handleClickOutside = event => {
      if (addNicknameRef.current && !addNicknameRef.current.contains(event.target)) {
        setFormData(prevState => ({ ...prevState, showAddNicknameForm: false }));
      }
    };

    const handleEscapeKey = event => {
      if (event.key === 'Escape') {
        setFormData(prevState => ({ ...prevState, showAddNicknameForm: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const handleAddNicknameClick = () => {
    setFormData(prevState => ({ ...prevState, showAddNicknameForm: !prevState.showAddNicknameForm }));
  };

  const handleCancelClick = () => {
    setFormData({ showAddNicknameForm: false, name: '', });
  };

  const validateAndSaveNickname = async () => {
    const { name } = formData;
    if (!name.trim()) {
      toast.error('يرجى إدخال الاسم');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}addNickname`, {
        to: phone,
        name,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = response.data;
      if (data.status === 'success') {
        toast.success(data.msg);
        setFormData({ showAddNicknameForm: false, name: '', phoneNumber: '' });
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      console.error('Error saving nickname:', error);
      toast.error('حدث خطأ أثناء حفظ الاتصال');
    }
  };

  return (
    <>
      <ToastContainer />
      {formData.showAddNicknameForm && <div className="overlay"></div>}
      <div ref={addNicknameRef}>
        <button className='chat-title-button primary-outline-button' onClick={handleAddNicknameClick}>تغيير الاسم</button>

        {formData.showAddNicknameForm && (
          <div className="add-contact-form add-nickname-form">
            <button className="x-button" onClick={handleCancelClick}><IoClose /></button>
            <div className='title-add'>اضافة متصل جديد</div>
            <input
              type="text"
              placeholder="الاسم"
              value={formData.name}
              onChange={e => setFormData({ ...formData, name: e.target.value })}
            />
            <button className='primary-button' onClick={validateAndSaveNickname}>حفظ</button>
            <button className='primary-outline-button' onClick={handleCancelClick}>الغاء</button>
          </div>
        )}
      </div>
    </>
  );
};

export default AddNicknameForm;
